/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useApi } from '../../hooks/useApi';
import styles from './conversions.module.css';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
require('moment/locale/ru');

import Select from '../../components/UI/Select/Select';

import { Managers as ManagersModel } from '../../models/managers';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DatePickerLocal from '../../components/UI/DatePicker/datePickerLocal';
import ConversionManagers from '../../api/analytics/dto/ConversionManagersResp.dto';
import { AppSettings } from '../../appSettings';
import React from 'react';
import Leagues from '../../api/managers/dto/GetLeaguesResponse.dto';
import { CategoryList } from '../../components/UI/Select/select.props';
import ManagersList from '../../api/managers/dto/GetManagersListResponse.dto';

const Conversions: React.FC = observer(() => {
  const api = useApi();
  const store = useStores();

  const [selectedRolesNames, setSelectedRolesNames] = useState<string[]>([
    'Консультант',
    'Консультант 2',
    'Консультант 3',
    'Консультант 4',
  ]);
  const [, setSelectedOp] = useState(0);

  const [managersList, setManagersList] = useState<ManagersModel[]>([]);

  const [selectedManager, setSelectedManager] = useState<ManagersModel>({
    id: 'all',
    name: 'Все менеджеры',
    post: '',
  });

  const [leagues, setLeagues] = useState<CategoryList[]>([]);
  const [selectedLeague, setSelectedLeague] = useState<string>('Все лиги');

  const [managerWithLeague, setManagerWithLeague] = useState<ManagersList[]>(
    [],
  );

  const [dateComplated, setDateComplated] = useState<[string, string]>([
    store.dateStore.startDate,
    store.dateStore.endDate,
  ]);

  const [managersStats, setManagersStats] = useState<ConversionManagers[]>([]);

  const [totalStats, setTotalStats] = useState<
    { typeName: string; income: number; realizated: number }[]
  >([]);

  useEffect(() => {
    (async () => {
      try {
        await loadManagers();
      } catch (error) {
        store.notificationsStore.setError('Ошибка при загрузке данных');
      }
    })();
  }, [selectedRolesNames]);

  useEffect(() => {
    (async () => {
      try {
        await loadDealStats();
      } catch (error) {
        store.notificationsStore.setError('Ошибка при загрузке данных');
      }
    })();
  }, [selectedManager, managersList, dateComplated]);

  useEffect(() => {
    (async () => {
      try {
        await loadManagers();
        await loadDealStats();
        await loadLeagues();
      } catch (error) {
        store.notificationsStore.setError('Ошибка при загрузке данных');
      }
    })();
  }, [store.dateStore.startDate, store.dateStore.endDate]);

  const loadManagers = async () => {
    const { body: managers } = await api.managers.getManangers(
      selectedRolesNames,
    );

    if (!managers) {
      return;
    }

    setManagersList([
      { id: 'all', name: 'Все менеджеры', post: '' },
      ...managers,
    ]);
    setSelectedManager({ id: 'all', name: 'Все менеджеры', post: '' });

    return managers;
  };

  const loadLeagues = async () => {
    const { body: leagues } = await api.managers.getLeagues();

    if (!leagues) {
      return;
    }

    setLeagues([
      { id: 'Все лиги', name: 'Все лиги' },
      ...leagues.map((item: Leagues) => ({
        id: item.league_name,
        name: item.league_name,
      })),
    ]);
    setSelectedLeague('Все лиги');

    const { body: managers } = await api.managers.getListManagers();

    if (!managers) {
      return;
    }

    setManagerWithLeague(managers);

    return;
  };

  const loadDealStats = async () => {
    try {
      store.notificationsStore.showPreloader();
      const responsables =
        selectedManager?.id !== 'all'
          ? [selectedManager?.id]
          : managersList.map((item) => item.id);

      const dataRequest = {
        start_date: store.dateStore.startDate,
        end_date: store.dateStore.endDate,
        responsables,
        complated_start_date: dateComplated[0],
        complated_end_date: dateComplated[1],
        realized_stages: AppSettings.realized_stages,
      };

      if (managersList.length > 0) {
        const { body } = await api.analytics.getConversionManagers(dataRequest);

        if (body) {
          const sortedManagersStats = body.map((item) => {
            const sortedTypeDeals = [...item.type_deals].sort(
              (a, b) => a.priority - b.priority,
            );
            return { ...item, type_deals: sortedTypeDeals };
          });

          //сортировать меннеджерв по высшему показателю конверсии у типа зявок с названием Бронь
          sortedManagersStats.sort((a, b) => {
            const aBron = a.type_deals.find(
              (item) => item.deal_type === 'Бронь',
            );
            const bBron = b.type_deals.find(
              (item) => item.deal_type === 'Бронь',
            );

            // Если оба менеджера имеют тип "Бронь", сортируем по коэффициенту конверсии
            if (aBron && bBron) {
              const aConversionRate =
                aBron.count !== 0 ? aBron.complated / aBron.count : 0;
              const bConversionRate =
                bBron.count !== 0 ? bBron.complated / bBron.count : 0;
              return bConversionRate - aConversionRate;
            }

            // Менеджеры с типом "Бронь" идут перед менеджерами без типа "Бронь"
            if (aBron) {
              return -1;
            }
            if (bBron) {
              return 1;
            }

            // Оба менеджера не имеют тип "Бронь", сохраняем исходный порядок
            return 0;
          });

          setManagersStats(sortedManagersStats);

          const totalStats: {
            typeName: string;
            income: number;
            realizated: number;
          }[] = [];

          sortedManagersStats.forEach((manager) => {
            manager.type_deals.forEach((typeDeal) => {
              const totalType = totalStats.find(
                (item) => item.typeName === typeDeal.deal_type,
              );

              if (totalType) {
                totalType.income += typeDeal.count;
                totalType.realizated += typeDeal.complated;
              } else {
                totalStats.push({
                  typeName: typeDeal.deal_type,
                  income: typeDeal.count,
                  realizated: typeDeal.complated,
                });
              }
            });
          });

          setTotalStats(totalStats);
        }
      }
    } catch (error) {
      store.notificationsStore.setError('Ошибка при загрузке данных');
    } finally {
      store.notificationsStore.hidePreloader();
    }
  };

  const selectOp = async (e: any) => {
    setSelectedOp(e.target.value);

    let currentSelectedRolesNames: string[] = [];

    if (+e.target.value === 0) {
      currentSelectedRolesNames = ['Консультант', 'Консультант 2'];
    }
    if (+e.target.value === 1) {
      currentSelectedRolesNames = ['Консультант'];
    }
    if (+e.target.value === 2) {
      currentSelectedRolesNames = ['Консультант 2'];
    }
    if (+e.target.value === 3) {
      currentSelectedRolesNames = ['Консультант 3'];
    }
    if (+e.target.value === 4) {
      currentSelectedRolesNames = ['Консультант 4'];
    }

    setSelectedRolesNames(currentSelectedRolesNames);
  };

  const selectManager = async (e: any) => {
    const selected = managersList.find((item) => item.id === e.target.value);
    setSelectedManager(
      selected !== undefined
        ? selected
        : { id: 'all', name: 'Все менеджеры', post: '' },
    );
  };

  const selectLeague = async (e: any) => {
    if (e.target.value === 'Все лиги') {
      setSelectedLeague('Все лиги');
      await loadManagers();
      await loadDealStats();
      return;
    } else {
      const managers = managerWithLeague.filter(
        (item) => item.league_name === e.target.value,
      );
      setManagersList([
        { id: 'all', name: 'Все менеджеры', post: '' },
        ...managers.map((item) => ({
          id: item.manager_id,
          name: item.league_name,
          post: '',
        })),
      ]);
      setSelectedManager({ id: 'all', name: 'Все менеджеры', post: '' });
      setSelectedLeague(e.target.value);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.pageName}>
          <div className={styles.top}>
            <div className={styles.title}>
              <h2>Конверсии</h2>
            </div>
            <div className={styles.filters}>
              {/* @ts-ignore */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p style={{ textAlign: 'left', margin: 0 }}>Дата закрытия</p>
                <DatePickerLocal onChangeDate={setDateComplated} />
              </div>
              <Select
                onChange={selectOp}
                style={{ minWidth: 170, marginRight: 10, width: 'max-content' }}
                listCategories={[
                  { id: 0, name: 'Все' },
                  { id: 1, name: 'Отдел продаж 1' },
                  { id: 2, name: 'Отдел продаж 2' },
                  { id: 3, name: 'Отдел продаж 3' },
                  { id: 4, name: 'Отдел продаж 4' },
                ]}
              />
              <Select
                selectedValue={selectedManager.id}
                onChange={selectManager}
                listCategories={managersList}
                style={{ minWidth: 250, width: 'max-content' }}
              />
              <Select
                selectedValue={selectedLeague}
                onChange={selectLeague}
                listCategories={leagues}
                style={{ minWidth: 250, width: 'max-content' }}
              />
            </div>
          </div>

          <div style={{ width: '100%', maxWidth: 800, margin: '5px' }}>
            <h3 style={{ marginBottom: 10 }}>Общее</h3>

            <Paper
              sx={{
                width: '100%',
                maxWidth: 800,
                margin: '5px',
                overflowX: 'auto',
              }}
            >
              <TableContainer style={{ marginTop: 20, overflowX: 'auto' }}>
                <Table sx={{ minWidth: 320 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Тип заявки</TableCell>
                      <TableCell align="center">Полученно</TableCell>
                      <TableCell align="center">Реализованно</TableCell>
                      <TableCell align="center">CV</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {totalStats.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.typeName}</TableCell>
                        <TableCell align="center">{row.income}</TableCell>
                        <TableCell align="center">{row.realizated}</TableCell>
                        <TableCell align="center">
                          {row.realizated / row.income
                            ? `${((row.realizated / row.income) * 100).toFixed(
                                0,
                              )} %`
                            : '0 %'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <h3 style={{ marginBottom: 10, marginTop: 20 }}>По менеджерам</h3>

            <Paper
              sx={{
                width: '100%',
                maxWidth: 800,
                margin: '5px',
                overflowX: 'auto',
              }}
            >
              <TableContainer style={{ marginTop: 20, overflowX: 'auto' }}>
                <Table sx={{ minWidth: 320 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Менеджер</TableCell>
                      <TableCell align="center">Тип заявки</TableCell>
                      <TableCell align="center">Полученно</TableCell>
                      <TableCell align="center">Реализованно</TableCell>
                      <TableCell align="center">CV</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {managersStats.map((row) => (
                      <React.Fragment key={row.manager_id}>
                        {row.type_deals.map((item, index) => (
                          <TableRow key={index}>
                            {index === 0 && (
                              <TableCell rowSpan={row.type_deals.length}>
                                {row.manager_name}
                              </TableCell>
                            )}
                            <TableCell align="center">
                              {item.deal_type}
                            </TableCell>
                            <TableCell align="center">{item.count}</TableCell>
                            <TableCell align="center">
                              {item.complated}
                            </TableCell>
                            <TableCell align="center">
                              {item.complated / item.count
                                ? `${(
                                    (item.complated / item.count) *
                                    100
                                  ).toFixed(0)} %`
                                : '0 %'}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
});

export default Conversions;
